import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

let urutan = 0;

export default {
  data() {
    return {
      isDataLoaded: true,
      headerSatkerName: "",
      headerVersionName: "",
      headerTotalAnggaran: 0,
      headerStatusApproval: "",
      headerNextApprover: "",
      selectedItem: [],

      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      pilihBalai: null,
      pilihProject: null,
      pilihSatker: null,
      isActive: [],
      listBalai: [],
      listProject: [],
      listStatker: [],
      listDataPaket: [],
      listGiat: [],
      listEvent: [],
      listVersi: [],
      listRegister: [],
      listKategori: [],
      listKodeAkun: [],
      tbData: [],
      pgMin: 0,
      pgMax: 0,
      noUrut: 1,
      columnTab: [
        { text: "Kode", value: "c_1" },
        { text: "Kegiatan/Output/Paket/Sub Paket", value: "c_2" },
        { text: "Target Volume", value: "c_3" },
        { text: "Lokasi", value: "c_4" },
        { text: "Jenis Paket", value: "c_5" },
        { text: "Metode Pemilihan", value: "c_6" },
        { text: "Kode Proyek", value: "c_7" },
        { text: "Kode Register", value: "c_8" },
        { text: "Kode KPPN", value: "c_9" },
        { text: "Kode Beban", value: "c_10" },
        { text: "Pagu (Rp Ribu)", value: "c_11" },
        { text: "Realisasi (Rp Ribu)", value: "c_12" },
        { text: "% KEU", value: "c_13" },
        { text: "% FISIK", value: "c_14" },
      ],
      dataVersi: {
        saktiVersionId: "",
        filename: "",
        crtdt: "",
        tahun: 0,
        kdSatker: "",
        saktiVersionNm: "",
        asLatest: true,
        saktiEventId: 0,
        saktiVersionType: "",
        programs: [],
      },
      currentSubOutput: {
        id: "",
        beforeVol: 0,
        vol: 0,
        name: "",
      },
      currentSubKomponen: {
        id: "",
        urskmpnen: "",
      },
      currentItem: {
        listProgramCombo: [],
        listGiatCombo: [],
        listOutputCombo: [],
        listSOutputCombo: [],
        listKomponenCombo: [],
        listSKomponenCombo: [],
      },
      currentItemEdit: {
        nmitem: "",
      },
      paramVolkeg: 0,
      paramHargaSat: 0,
      paramJumlah: 0,
      currentKomentar: {
        key: 0,
        kdHistory: "",
        komentar: "",
        indexEdit: 0,
      },
      currentKomentarEdit: {
        key: 0,
        kdHistory: "",
        komentar: "",
        indexEdit: 0,
      },
      listKomentar: [],
    };
  },
  props: {
    id: [String, String],
    paramSatker: [String, String],
    paramNamaVersi: [String, String],
    paramTotalAnggaran: [String, Number],
  },
  computed: {
    isCurrentApprover: function() {
      console.log("masuk compute current approver");
      console.log(this.getUser);
      console.log(this.dataVersi);
      if (this.dataVersi.saktiVersionId) {
        console.log("masuk version id");
        if (
          this.dataVersi.nextApprover != undefined &&
          this.getUser?.userGroup != undefined &&
          this.getUser?.userGroup != null
        ) {
          console.log("masuk cek next approver");
          console.log(this.dataVersi.nextApprover);
          console.log(this.getUser.userGroup);
          if (this.dataVersi.nextApprover == this.getUser.userGroup)
            return true;
          else return false;
        } else {
          console.log("tidak masuk cek next approver");
          return false;
        }
      } else {
        return false;
      }
    },
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    console.log(this.id);
    console.log(this.paramSatker);

    if (this.paramSatker) this.headerSatkerName = this.paramSatker;
    if (this.paramNamaVersi) this.headerVersionName = this.paramNamaVersi;
    if (this.paramTotalAnggaran)
      this.headerTotalAnggaran = this.paramTotalAnggaran;

    this.cariData();
  },
  components: {
    myTable,
  },
  methods: {
    getRegisterAkun: function(register) {
      if (register.trim()) {
        return "/ Reg. " + register;
      }

      return "";
    },
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }
    },

    async cariData() {
      console.log("masuk ke cari data");
      this.G_GetAny("rkakl/data/approval?saktiVersionId=" + this.id).then(
        (res) => {
          console.log("data versi");
          console.log(res.data);
          this.dataVersi = res.data;

          this.headerSatkerName = this.dataVersi.satker?.satkerNm;
          this.headerVersionName = this.dataVersi.saktiVersionNm;
          this.headerStatusApproval = this.dataVersi.statusApproval.statusApprovalNm;
          this.headerNextApprover = this.dataVersi.nextApprover;

          this.isDataLoaded = false;

          $("#tableShow").fadeIn();
          this.$refs.btnAct.load(false);
          this.loadingTb = false;
        }
      );
    },

    async kirimSemuaDataApproval() {
      let judul = "Monitoring Persetujuan";
      let subJudul = "Apakah anda akan menyetujui semua perubahan versi ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();

        let param = {
          saktiVersionId: this.id,
        };

        var url = "";

        if (this.getUser.userGroup == "balai") {
          url = "rkakl/versi/approval/balai/all";
        } else if (this.getUser.userGroup == "direktorat") {
          url = "rkakl/versi/approval/direktorat/all";
        } else if (this.getUser.userGroup == "ditjensda") {
          url = "rkakl/versi/approval/ditjensda/all";
        }

        this.G_PostAnyCustom(url, param)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.$refs.confirm.close();
              // this.$refs.approvalTable.resetApprovalSelection();
              this.cariData();
              this.$refs.notif.success(`Berhasil menyetujui Approval Versi`);
            } else {
              this.$refs.confirm.close();
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },

    async kirimDataApprovalTerpilih() {
      let judul = "Monitoring Persetujuan";
      let subJudul = "Apakah anda akan menyetujui pilihan perubahan versi ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        this.$refs.confirm.loading();

        var param = {
          saktiVersionId: this.id,
          listSaktiItemId: [],
        };
        var clist = document.getElementsByClassName("checkbox_item");
        console.log("sedang print");
        for (var i = 0; i < clist.length; ++i) {
          if (clist[i].checked) {
            console.log(clist[i].value);
            param.listSaktiItemId.push(clist[i].value);
          }
        }

        var url = "";

        if (this.getUser.userGroup == "balai") {
          url = "rkakl/versi/approval/balai";
        } else if (this.getUser.userGroup == "direktorat") {
          url = "rkakl/versi/approval/direktorat";
        } else if (this.getUser.userGroup == "ditjensda") {
          url = "rkakl/versi/approval/ditjensda";
        }

        this.G_PostAnyCustom(url, param)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.$refs.confirm.close();
              // this.$refs.approvalTable.resetApprovalSelection();
              this.cariData();
              this.$refs.notif.success(`Berhasil menyetujui Approval Versi`);

              var clist = document.getElementsByClassName("all_checkbox");
              for (var i = 0; i < clist.length; ++i) {
                clist[i].checked = false;
              }
            } else {
              this.$refs.confirm.close();
              this.$refs.notif.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },

    onClickCheckbox(paramId, event) {
      console.log(paramId);
      console.log(event.target.checked);

      if (event.target.checked) {
        var clist = document.getElementsByClassName(paramId);
        for (var i = 0; i < clist.length; ++i) {
          clist[i].checked = true;
        }
      } else {
        var clist = document.getElementsByClassName(paramId);
        for (var i = 0; i < clist.length; ++i) {
          clist[i].checked = false;
        }
      }
    },
  },
};
